import * as $ from 'jquery';
import * as moment from 'moment';

$(document).ready(function() {

    runProgress();
    volumeRange();

    function runProgress() {
        let $progress = $('.progress');
        let $progressBar = $('.progress .progress-bar');
        let $progressBarText = $('.progress .progress-bar span');

        if(!$progress.length) {
            checkForNewSong();
            return;
        }

        let duration = moment.duration($progress.data('duration'));
        let start = moment($progress.data('start'));
        let end = start.clone().add(duration);

        let total = moment.duration(end.diff(start)).asSeconds();
        let interval = setInterval(runInterval, 1000);

        runInterval();

        function runInterval() {
            let diff = moment.duration(moment().diff(start));
            let now = diff.asSeconds();
            let percent = (now * 100) / total;

            $progressBar.css('width', percent + '%');
            $progressBarText.text(getFormattedDuration(diff));

            if(percent >= 100) {
                console.log('clear interval');
                clearInterval(interval);
                setTimeout(checkForNewSong, 1000);
            }
        }

        function checkForNewSong() {
            let songId = null;

            if($progress.length) {
                songId = parseInt($progress.closest('.playback-item').data('song-id'));
            }

            let interval = setInterval(function() {
                $.get('/api/playback/current', function(res) {
                    if(!res) {
                        return;
                    }

                    if(parseInt(res.id) !== songId) {
                        clearInterval(interval);
                        window.location.reload();
                    }
                });
            }, 5000);
        }

        function getFormattedDuration(duration) {
            let minutes = duration.minutes();
            let seconds = duration.seconds();

            if(minutes < 10 && minutes > 0) {
                minutes = '0' + minutes;
            }

            if(seconds < 10) {
                seconds = '0' + seconds;
            }

            return minutes + ':' + seconds;
        }
    }

    function volumeRange() {
        let $volume = $('input[name="volume"]');
        let $volumeText = $('#volume-text');

        $volume.on('input', function(e) {
            $volumeText.text($volume.val());
        });

        $volume.on('change', function(e) {
            $(this).closest('form').submit();
        })
    }
});
